import { createI18n } from "vue-i18n";
import enUS from "./en";
import zhCN from "./zh";
import zhTW from "./zh-tw";
import vi from "./vi";
 
const message = {
  en: {
    ...enUS,
  },
  zh: {
    ...zhCN,
  },
  'zh-tw': {
    ...zhTW,
  },
  vi: {
    ...vi
  }
};
 
const i18n = createI18n({
  locale: "zh", // 设置语言类型
  legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
  globalInjection: true, // 全局注册$t方法
  messages: message,
});
 
export default i18n;